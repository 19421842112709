<template>
  <div class="px-1 px-md-4 pt-4 pb-4 pb-md-16" v-if="serverData.length">
    <div
      class="w-100 row ma-0 mb-0"
      v-for="(group, groupIndex) in serverData"
      :key="groupIndex"
    >
      <div
        class="col-12 col-md-6 px-md-3 py-1 poppins"
        v-for="(subGroup, subGroupIndex) in group"
        :key="subGroupIndex"
      >
        <div class="w-100 bg-transparent mb-1">
          <div class="dashboard-top-subgroup-container">
            <span class="dashboard-top-subgroup-title">{{
              subGroup.title
            }}</span>
          </div>
          <div class="">
            <v-card
              elevation="0"
              hover
              class="d-flex dashboard-top-card-inner pa-0"
            >
              <v-card-text
                class="dashboard-top-card-title w-100 d-flex align-items-center rounded-0 px-4 py-2"
                style="background-color: #9b21ff"
              >
                <div class="w-25 px-2"><span>Type</span></div>
                <div
                  class="w-75 d-flex align-items-center justify-space-between"
                >
                  <div class="w-100 d-flex align-center justify-center">
                    <span>SDD</span>
                  </div>
                  <div class="w-100 d-flex align-center justify-center">
                    <span>NDD</span>
                  </div>
                  <div class="w-100 d-flex align-center justify-center">
                    <span>B2B</span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div class="dashboard-top-container w-100">
            <div
              v-for="(counter, index) in subGroup.counters"
              :key="counter.title"
              class="w-100 d-flex align-items-center"
            >
              <v-card
                elevation="0"
                hover
                class="w-100 rounded-0 d-flex dashboard-top-card-inner pa-0"
              >
                <v-card-text
                  class="dashboard-top-card-data w-100 d-flex align-items-center px-4"
                  :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
                >
                  <div class="w-25 px-2 font-title">
                    <span>{{ counter.title }}</span>
                  </div>
                  <div
                    class="w-75 d-flex align-items-center justify-space-between font-weight-black font-numbers"
                  >
                    <div class="w-100 d-flex align-center justify-center">
                      <span>{{ counter.sdd }}</span>
                    </div>
                    <div class="w-100 d-flex align-center justify-center">
                      <span>{{ counter.ndd }}</span>
                    </div>
                    <div class="w-100 d-flex align-center justify-center">
                      <span>{{ counter.b2b }}</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardOperationGroup",
  props: ["serverData"],
};
</script>
<style scoped>
.dashboard-top-subgroup-container {
  background-color: #172145;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dashboard-top-container {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
}

.dashboard-top-container > div:last-child .v-card {
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.dashboard-top-card-inner {
  width: 100%;
  height: 100%;
}

.dashboard-top-subgroup-title {
  color: white;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.1;
  word-wrap: break-word;
}
.dashboard-top-card-title {
  color: white !important;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.1;
  word-wrap: break-word;
}
.dashboard-top-card-data {
  color: #172145 !important;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.1;
  word-wrap: break-word;
  padding-top: 16px;
  padding-bottom: 16px;
}

.font-title {
  font-size: 19px;
}

.font-numbers {
  font-size: 26px !important;
}

@media (min-width: 400px) {
  .font-title {
    font-size: 20px;
  }

  .font-numbers {
    font-size: 28px !important;
  }
}

@media (min-width: 760px) {
  .font-numbers {
    font-size: 36px !important;
  }
  .dashboard-top-subgroup-title {
    font-size: 30px;
  }

  .dashboard-top-card-title,
  .font-title {
    font-size: 24px;
  }
}

@media (min-width: 1465px) {
  .dashboard-top-subgroup-title {
    font-size: 34px;
  }
  .dashboard-top-card-title,
  .font-title {
    font-size: 30px;
  }
}

@media (min-width: 2560px) {
  .dashboard-top-subgroup-container {
    //padding-top: 16px;
    //padding-bottom: 16px;
  }
  .dashboard-top-subgroup-title {
    font-size: 44px;
  }
  .dashboard-top-card-title,
  .font-title {
    font-size: 40px;
    //padding-top: 20px;
    //padding-bottom: 20px;
  }
}

@media (min-width: 3830px) {
  .dashboard-top-subgroup-container {
    //padding-top: 16px;
    //padding-bottom: 16px;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
  }
  .dashboard-top-container > div:last-child .v-card {
    border-bottom-left-radius: 36px !important;
    border-bottom-right-radius: 36px !important;
  }
  .dashboard-top-subgroup-title {
    font-size: 56px;
  }
  .dashboard-top-card-title,
  .font-title {
    font-size: 52px;
    //padding-top: 26px;
    //padding-bottom: 26px;
  }
}
</style>
