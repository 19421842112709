<template>
  <div class="pb-12 poppins">
    <div
      class="px-1 px-md-4 mb-2 mb-md-4 d-flex justify-content-center justify-content-md-end"
    >
      <div class="d-flex align-items-center">
        <DashboardWarehouseSelect :updateData="loadInitialDataFromServer" />

        <v-icon size="44" color="pink" @click="toggleModal"
          >mdi-fullscreen</v-icon
        >
      </div>
    </div>

    <DashboardPerformanceGroup :serverData="serverData" />

    <v-dialog v-model="dialog" fullscreen>
      <div
        style="
          background-color: #ebedf3;
          min-height: fit-content;
          height: 100vh;
          width: 100%;
          position: relative;
        "
        class="pt-16 pt-md-0 pb-8 pb-0"
      >
        <div
          class="px-1 px-md-4 pt-2 pb-0 position-absolute"
          style="display: flex; justify-content: end; bottom: 0; right: 0"
        >
          <v-icon x-large color="pink" @click="toggleModal"
            >mdi-fullscreen-exit</v-icon
          >
        </div>

        <DashboardPerformanceGroup :serverData="serverData" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DashboardPerformanceGroup from "@/own/components/dashboard/DashboardPerformanceGroup.vue";
import DashboardWarehouseSelect from "@/own/components/dashboard/DashboardWarehouseSelect.vue";
import {
  SET_WAREHOUSE,
  SET_WAREHOUSES_LIST,
} from "@/core/services/store/auth.module";

export default {
  name: "DashboardPerformance",
  components: { DashboardPerformanceGroup, DashboardWarehouseSelect },
  data: () => ({
    dialog: false,
    serverData: [],
    // serverData: null,
    intervalId: null,
    refreshInterval: 300000, // refresh interval in ms
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.loadInitialDataFromServer();
    this.startAutoRefresh();
    setTimeout(() => {
      this.dialog = true;
    }, 1000);
  },
  beforeDestroy() {
    this.stopAutoRefresh();
  },
  methods: {
    startAutoRefresh() {
      this.intervalId = setInterval(() => {
        this.loadInitialDataFromServer(
          true,
          this.$store.getters.getSelectedWarehouse
        );
      }, this.refreshInterval);
    },
    stopAutoRefresh() {
      clearInterval(this.intervalId);
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    loadInitialDataFromServer(forAutoRefresh = false, param = null) {
      let filterData = {
        date_range_type: "custom",
        date_range: ["2021-01-15", "2021-02-14"],
      };

      let url = param
        ? `/dashboards/dashboardPerformance?warehouse_id=${param}`
        : "/dashboards/dashboardPerformance";

      ApiService.post(url, filterData)
        .then((response) => {
          // console.log(response.data);
          // this.serverData = response.data;
          this.serverData = response.data.data[0];
          if (!forAutoRefresh) {
            this.$store.commit(
              SET_WAREHOUSE,
              response.data.selected_warehouse_id
            );
            this.$store.commit(SET_WAREHOUSES_LIST, response.data.warehouses);
          }
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
</script>
